<template>
  <div>
    <v-row justify="center" align="center">
      <v-col cols="8">
        <v-text-field
          placeholder="Search"
          rounded
          v-model.number="searchQ"
          filled
          dense
          prepend-inner-icon="mdi-magnify"
          hide-details
        />
      </v-col>
      <!-- <v-col cols="2">
        <v-icon class="mt-1"> mdi-filter </v-icon>
      </v-col> -->
    </v-row>
    <!-- searchfield -->
    <h5 v-if="!filterRes.length" class="text-center mt-4">No Data Found</h5>

    <template v-else>
      <ReservationCard
        v-for="res in filterRes"
        :key="res.id"
        :reservation="res"
      ></ReservationCard>
    </template>
  </div>
</template>

<script>
import ReservationCard from '@/components/Reservation/ReservationCard';
export default {
  name: 'ReservationListwithSearch',
  components: {
    ReservationCard,
  },
  props: ['list'],
  data: function () {
    return {
      searchQ: '',
    };
  },
  computed: {
    filterRes() {
      if (this.searchQ) {
        return this.list.filter((res) => {
          return res.id == this.searchQ;
        });
      } else return this.list;
    },
  },
};
</script>

<style scoped></style>
