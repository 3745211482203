<template>
  <v-card color="mb-3">
    <div class="d-flex align-items-center">
      <v-list-item class="align-items-center details">
        <v-list-item-content>
          <v-list-item-title>
            <a :href="merchantHostname" class="ml-2 text-decoration-none">
              <div class="d-flex">
                <b>#{{ reservation.id }} </b>
                <b class="ml-2">
                  {{ reservation.merchantName }}
                </b>
                <span class="text-caption ml-2">{{
                  reservation.merchantPhoneNo
                }}</span>
              </div>
            </a>
          </v-list-item-title>

          <v-list-item-subtitle>
            <b>{{ reservation.dateTime }}</b>
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <v-icon>mdi-account-multiple</v-icon>
            {{ reservation.noOfGuest }} Person
          </v-list-item-subtitle>
          <v-list-item-subtitle class="notes">
            {{ reservation.notes }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <div class="mr-4">
        <v-chip small :color="statusColor">{{
          reservation.status | capitalize
        }}</v-chip>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ['reservation'],
  computed: {
    status() {
      if (this.reservation.status === 'denied') {
        return 'declined';
      }
      return this.reservation.status;
    },
    statusColor() {
      if (this.reservation.status === 'denied') {
        return 'error';
      } else if (this.reservation.status === 'pending') {
        return 'warning';
      } else {
        return 'success';
      }
    },
    merchantHostname() {
      return this.reservation.merchantHostname.includes('https://') ||
        this.reservation.merchantHostname.includes('http://')
        ? this.reservation.merchantHostname
        : `https://${this.reservation.merchantHostname}`;
    },
  },
};
</script>

<style scope>
.notes {
  white-space: pre-wrap;
}
.details {
  max-width: 80%;
}
</style>
